import React from "react"
import { Image } from "semantic-ui-react"

import michaelPortrait from "../assets/img/michael-portrait.jpeg"
import joshPortrait from "../assets/img/josh-portrait.jpeg"
import chrisPortrait from "../assets/img/chris-portrait.jpeg"
import ericPortrait from "../assets/img/eric-portrait.jpeg"

const getMemberImg = name => {
  if (name === "Michael Paccione") {
    return michaelPortrait
  } else if (name === "Joshua Rubin") {
    return joshPortrait
  } else if (name === "Eric Okemmadu") {
    return ericPortrait
  } else if (name === "Christopher Paccione") {
    return chrisPortrait
  }
  return ""
}

const Team = props => {
  const { teamArray } = props
  console.log({ props })
  return (
    <div>
      {teamArray && teamArray.length > 0 && (
        <div style={{ display: "flex", justifyContent: "flex-start", marginTop: '30px' }}>
          {teamArray.map((member, index) => (
            <span key={index} style={{ display: 'flex', marginRight: '15px' }}>
              <Image
                src={getMemberImg(member.value)}
                style={{ height: " 100px", marginRight: "15px", marginBottom: '0px' }}
              />
              <div
                style={{
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h2>{member.value}</h2>
                <h3>{member.label}</h3>
              </div>
            </span>
          ))}
        </div>
      )}
    </div>
  )
}

export default Team
