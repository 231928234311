import React, { Component } from "react"
import { Segment } from "semantic-ui-react"
import { graphql } from "gatsby"
import { document } from "browser-monads"
import Header from "../components/header"
import PropTypes from "prop-types"
import Contact from "../components/contact"
import SinglePostContent from "../components/singlePostContent"
import "../css/post.css"

export const query = graphql`
  query post($id: String!) {
    page: wpPost(id: { eq: $id }) {
      content
      featuredImage {
        sourceUrl
      }
      gallery {
        gallery {
          sourceUrl
        }
      }
      projectLinks {
        repoLink
        viewingLink
      }
      tags {
        nodes {
          name
        }
      }
      teamSection {
        teamMembers
      }
      title
      video {
        videoIframe
      }
    }
  }
`
// Reset Scroll
document.body.scrollTo(0, 0)

class Post extends Component {
  render() {
    const post = this.props.data.page

    return (
      <div>
        <Header color="purple" />
        <Segment
          style={{ border: "none", boxShadow: "none", minHeight: "800px" }}
        >
          <div style={{ marginBottom: "60px" }}>
            <SinglePostContent
              title={post.title}
              video={post.video}
              content={post.content}
              tags={post.tags}
              teamMembers={post.teamSection.teamMembers}
              iframe={post.video.videoIframe}
              images={post.gallery.gallery}
              repoLink={post.projectLinks.repoLink}
              viewingLink={post.projectLinks.viewingLink}
            />
          </div>
        </Segment>
        <Contact />
      </div>
    )
  }
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default Post
