import React, { useState, useEffect } from "react"
import TeamMembers from "./teamMembers"
import { Button, Icon } from "semantic-ui-react"
import Lightbox from "react-image-lightbox"
import { document, window } from "browser-monads"

const debounce = require("lodash.debounce")

const createMarkup = content => {
  return {
    __html: content,
  }
}

const PostContent = props => {
  const {
    content,
    teamMembers,
    title,
    tags,
    iframe,
    images,
    repoLink,
    viewingLink,
  } = props

  // Btn Width for Sidebar
  let btnWidth
  let count = 0
  if (viewingLink) {
    count++
  }
  if (repoLink) {
    count++
  }
  if (images) {
    count++
  }
  btnWidth = { flexBasis: `${97 / count}%` }

  // Lightbox
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  // Scroll
  const [fixedSidebar, setFixedSidebar] = useState(true)
  let footerOffset

  const sidebarScroll = bodyScroll => {
    bodyScroll > footerOffset - 250
      ? setFixedSidebar(false)
      : setFixedSidebar(true)
  }

  const debounceSidebarScroll = e => {
    // debounce(e => {
    sidebarScroll(e.srcElement.scrollTop)
    // }, 10)()
  }

  const footerOffsetFunc = () => {
    // debounce(e => {
    const contact = document.querySelector(".contact")
    footerOffset = contact.getBoundingClientRect().top - contact.offsetHeight

    document.body.addEventListener("scroll", debounceSidebarScroll)
    // }, 25)
  }

  useEffect(() => {
    if (window.innerWidth <= 1023) {
      setFixedSidebar(false)
    } else {
      footerOffsetFunc()
      window.addEventListener("resize", footerOffsetFunc)
    }

    return () => {
      window.removeEventListener("resize", footerOffsetFunc)
      document.body.removeEventListener("scroll", debounceSidebarScroll)
    }
  }, [])

  return (
    <>
      <div id="postContainer" className={fixedSidebar ? "fixedSidebar" : ""}>
        <div id="postSidebar" className={fixedSidebar ? "fixedSidebar" : ""}>
          {iframe && (
            <div
              dangerouslySetInnerHTML={createMarkup(iframe)}
              style={{ marginRight: "30px" }}
            />
          )}
          <div id="postSidebarBtnWrap">
            {viewingLink && (
              <a href={`${viewingLink}`} target="_blank" style={btnWidth}>
                <Button style={{ width: "100%" }}>
                  <Icon name="eye" />
                  Viewing Link
                </Button>
              </a>
            )}
            {repoLink && (
              <a href={`${repoLink}`} target="_blank" style={btnWidth}>
                <Button style={{ width: "100%" }}>
                  <Icon name="code" />
                  Github Link
                </Button>
              </a>
            )}
            {images && (
              <Button onClick={() => setIsOpen(true)} style={btnWidth}>
                <Icon name="images" />
                Open Gallery
              </Button>
            )}
          </div>
        </div>
        <div id="postContent" className={fixedSidebar ? "fixedSidebar" : ""}>
          {title && <h1 style={{ marginTop: "100px" }}>{title}</h1>}
          {tags && tags.length > 0 && (
            <div className="tag-container-post">
              <ul>
                {tags.map((tag, index) => (
                  <li key={index}>{tag.name}</li>
                ))}
              </ul>
            </div>
          )}
          <div dangerouslySetInnerHTML={createMarkup(content)} />
          {teamMembers && <TeamMembers teamArray={teamMembers} />}
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].sourceUrl}
          nextSrc={images[(photoIndex + 1) % images.length].sourceUrl}
          prevSrc={
            images[(photoIndex + images.length - 1) % images.length].sourceUrl
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            const index = (photoIndex + images.length - 1) % images.length
            setPhotoIndex(index)
          }}
          onMoveNextRequest={() => {
            setPhotoIndex((photoIndex + 1) % images.length)
          }}
        />
      )}
    </>
  )
}

export default PostContent
